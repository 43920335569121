import { Overrides } from "@material-ui/core/styles/overrides"
import { ComponentsProps } from "@material-ui/core/styles/props"

export const overrides: Overrides = {
    MuiFab: {
        root: {
            boxShadow: "none"
        },
        label: {
            fontWeight: "bold",
            lineHeight: "16px",
            letterSpacing: "1.3px",
            paddingLeft: 10,
            paddingRight: 10
        }
    },
    MuiPaper: {
        root: {
            border: "1px solid rgba(0, 58, 146, 0.12)",
            borderRadius: 8
        }
    }
}
export const props: ComponentsProps = {
    MuiFab: {
        size: "small",
        variant: "extended",
        color: "primary"
    },
    MuiPaper: {
        elevation: 0
    }
}
