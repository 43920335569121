import { Router } from "@reach/router"
import MainLayout from "components/organisms/Layout/MainLayout"
import React from "react"
import DataDashboardPage from "./pages/DataDashboardPage"
import HomePage from "./pages/HomePage"
import ProjectPage from "./pages/ProjectPage"
import StoryPage from "./pages/StoryPage"
import { Route, RouteInfo } from "./Route"

interface RouterProps {
    className?: string
    style?: React.CSSProperties
}

export enum PATHS {
    ROOT = "/*",
    HOME = "/home",
    DASHBOARD = "/dashboard",
    PROJECT = "/the-project",
    STORY = "/story"
}

const navigationGuards = {
    layout: (to: RouteInfo, from?: RouteInfo): Promise<string | boolean> => {
        return new Promise(resolve => {
            if (to && (to.fullPath === "/*" || to.fullPath === "/")) {
                return resolve(PATHS.HOME)
            }
            return resolve(true)
        })
    },
    app: async (to: RouteInfo, from?: RouteInfo): Promise<string | boolean> => {
        // do auth & other checks here
        return true
    }
}

const Layout: React.FC<RouterProps> = props => (
    <Router className={props.className} style={props.style}>
        <Route path={PATHS.ROOT} guard={navigationGuards.layout} component={MainLayout} />
    </Router>
)

const Main: React.FC<RouterProps> = props => {
    return (
        <div>
        <p><h3>
            This site is under construction. 
            </h3>
            <h5>
            Predictions of bloom levels have been halted due to complications resulting from the 2020 wildfires.
            </h5>
            <h5>
            Check back in for changes and updates in the future.
            </h5>
            <h5>
            Reach out to us at <a href="mailto: info@thepredictionlab.com">info@thepredictionlab.com</a> with questions.
            </h5>
        </p>
    </div>
    )
}

export const AppRouter = { Layout, Main }
