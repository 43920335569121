import { PaletteOptions } from "@material-ui/core/styles/createPalette"

const palette: PaletteOptions = {
    common: {
        black: "#000",
        white: "#fff"
    },
    background: { paper: "#F9FBFF", default: "#FDFEFF" },
    primary: {
        light: "#66CEFF",
        main: "#159DE0",
        dark: "#006FAE",
        contrastText: "#fff"
    },
    secondary: {
        light: "#9BF0C5",
        main: "#38E28C",
        dark: "#00AF5E",
        contrastText: "#fff"
    },
    error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff"
    },
    text: {
        primary: "#0D2346",
        secondary: "rgba(13, 35, 70, 0.72)",
        disabled: "rgba(13, 35, 70, 0.4)",
        hint: "rgba(13, 35, 70, 0.4) "
    },
    // levels low to high
    info: {
        light: "#66CEFF", // low
        main: "#1BD7BB", // med
        dark: "#38E28C" // high
    },
    divider: "rgba(0, 58, 156, 0.12)"
}

export const TODAY_COLOR = "#b0413e"
//@ts-ignore
export const LINKED_POINTER_COLOR = palette.info.light

export const HEATMAP_CELL_BORDER_COLOR = "#AAA"
export const HEATMAP_NAN_COLOR = "#FFF"

export const TOXIN_THRESHOLD_COLORS = ["rgb(246, 204, 184)", "rgb(235, 114, 84)"]

export const ALGAE_THRESHOLD_COLORS = ["rgb(227, 242, 221)", "rgb(183, 220, 172)", "rgb(128, 189, 121)", "rgb(77, 148, 86)"]

export default palette
