import AppContext from "./modules/AppContext"
import Data from "./modules/Data"
import Route from "./modules/Route"
import Toasts from "./modules/Toasts"
import Weather from "./modules/Weather"

export class Store {
    appContext: AppContext
    data: Data
    route: Route
    toasts: Toasts
    weather: Weather

    constructor() {
        this.appContext = new AppContext()
        this.data = new Data()
        this.route = new Route()
        this.toasts = new Toasts()
        this.weather = new Weather()
    }

    async init() {
        const promises = [this.appContext.init(this), this.data.init(this), this.route.init(this), this.toasts.init(this), this.weather.init(this)]
        return Promise.all(promises)
    }
}

export default new Store()
