import axios, { AxiosResponse } from "axios"
import { computed, observable } from "mobx"
import Base from "./Base"

const apiKey = "c4ddbeff65143510d8fba15cd3d9f970"
const detroitLakeLatLong = { lat: 44.72968, long: -122.17439 }
const openWeatherURL = "https://api.openweathermap.org/data/2.5/"
const currentWeather = `${openWeatherURL}weather?units=imperial&lat=${detroitLakeLatLong.lat}&lon=${detroitLakeLatLong.long}&appid=${apiKey}`
export default class Weather extends Base {
    @observable
    state = {
        temp: "",
        icon: "",
        desc: ""
    }

    @computed
    get formattedTemp() {
        return parseInt(this.state.temp, 10).toFixed(0) + "°F"
    }

    @computed
    get iconURL() {
        return `http://openweathermap.org/img/wn/${this.state.icon}@2x.png`
    }
    actions = {
        getCurrentWeather: async () => {
            const { data }: AxiosResponse = await axios.get(currentWeather)
            this.state.temp = data.main.temp
            this.state.icon = data.weather[0].icon
            this.state.desc = data.weather[0].description
        }
    }
}
