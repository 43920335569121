import axios, { AxiosResponse } from "axios"
import { observable } from "mobx"
import {
    DashboardMetResponse,
    DashboardSiteResponse,
    DashboardYsiResponse,
    DASHBOARD_MET_API_ENDPOINTS,
    DASHBOARD_SITE_API_ENDPOINTS,
    DASHBOARD_YSI_API_ENDPOINTS,
    HomePageResponse,
    HOME_PAGE_API_ENDPOINTS,
    IsoDateTimeString,
    SoWhatPageResponse,
    SO_WHAT_PAGE_API_ENDPOINTS
} from "../../data-collection"
import { Store } from "../Store"
import Base from "./Base"
import { addOffset, dateStringToMomentDate, getDateTimeString, getNow, getUtcOffset, localToUtc } from "utils/utils"
import moment, { Moment } from "moment"
import { Pointer } from "components/molecules/Home/Timeline"
import { RangeOption } from "components/molecules/DateRange"

export const INITIAL_DATE_RANGE_INDEX = 2
export const DEFAULT_RANGE = 14
export const MIN_DATE = dateStringToMomentDate("2020-01-01T00:00:00")
export const MAX_DATE = getNow().add(DEFAULT_RANGE, "d")
export const DEFAULT_START_DATE = getNow()
    .subtract(DEFAULT_RANGE, "d")
    .startOf("day")
export const DEFAULT_END_DATE = getNow()
    .add(DEFAULT_RANGE, "d")
    .startOf("day")

const getBaseApiUrl = () => {
    const host = window.location.host
    let baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/production/current"
    if (host.includes("localhost")) {
        // baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/staging/current"
        baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/production/current"
    } else if (host.includes("staging")) {
        // baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/staging/current"
        baseApiUrl = "https://dataviz0957.s3-us-west-2.amazonaws.com/production/current"
    }
    return baseApiUrl
}

const BASE_API_URL = getBaseApiUrl()

export interface State {
    cachedResponses: Map<
        HOME_PAGE_API_ENDPOINTS | DASHBOARD_YSI_API_ENDPOINTS | DASHBOARD_SITE_API_ENDPOINTS | DASHBOARD_MET_API_ENDPOINTS | SO_WHAT_PAGE_API_ENDPOINTS,
        HomePageResponse | DashboardSiteResponse | DashboardMetResponse | DashboardYsiResponse | SoWhatPageResponse
    >
    homePageData?: HomePageResponse
    dashboardMetDataList: [DashboardMetResponse | undefined, DashboardMetResponse | undefined]
    dashboardSiteData?: DashboardSiteResponse
    dashboardYsiData?: DashboardYsiResponse
    projectPageData?: SoWhatPageResponse
    algaeTimeSeriesPointer?: any
    toxinTimeSeriesPointer?: any
    pointer?: Pointer
    algaeMinDate?: IsoDateTimeString
    algaeMaxDate?: IsoDateTimeString
    ranges: RangeOption[]
}

export default class Data extends Base {
    @observable
    state: State = {
        cachedResponses: new Map(),
        homePageData: undefined,
        dashboardMetDataList: [undefined, undefined],
        dashboardSiteData: undefined,
        dashboardYsiData: undefined,
        projectPageData: undefined,
        algaeTimeSeriesPointer: undefined,
        toxinTimeSeriesPointer: undefined,
        pointer: undefined,
        algaeMinDate: undefined,
        algaeMaxDate: undefined,
        ranges: []
    }

    dateFilter = (datum: any[], startDate?: Moment, endDate?: Moment): boolean => {
        const date = dateStringToMomentDate(datum[0])
        let startCondition = true
        let endCondition = true
        if (startDate) {
            startCondition = date.diff(startDate, "d") >= 0
        }
        if (endDate) {
            endCondition = date.diff(endDate, "d") <= 0
        }
        if (startCondition && endCondition) {
            return true
        }
        return false
    }

    sortHomePageResponse = (response: HomePageResponse): HomePageResponse => {
        const algaeDataSet = response.dataSets[1]
        const toxinDataSet = response.dataSets[2]

        // sort
        algaeDataSet.dataSet.sort((a, b) => {
            const aDate = dateStringToMomentDate(a[0])
            const bDate = dateStringToMomentDate(b[0])
            return aDate < bDate ? -1 : 1
        })
        toxinDataSet.dataSet.sort((a, b) => {
            const aDate = dateStringToMomentDate(a[0])
            const bDate = dateStringToMomentDate(b[0])
            return aDate < bDate ? -1 : 1
        })
        return response
    }
    filterHomePageResponse = (response: HomePageResponse, startDate?: Moment, endDate?: Moment): HomePageResponse => {
        const algaeDataSet = response.dataSets[1]
        const toxinDataSet = response.dataSets[2]

        const filteredAlgae = algaeDataSet.dataSet.filter(datum => {
            return this.dateFilter(datum, startDate, endDate)
        })

        const filteredToxin = toxinDataSet.dataSet.filter(datum => {
            return this.dateFilter(datum, startDate, endDate)
        })
        response.dataSets[1].dataSet = filteredAlgae
        response.dataSets[2].dataSet = filteredToxin

        // Adding in last date with nulls if endDate is geater than the last dataset date
        // We need this to force the x-axis on the Nivo graphs to go all the way to the last
        // filter value date.
        const lastDatasetDate = filteredAlgae[filteredAlgae.length - 1][0]

        if (endDate) {
            const newEndDate = localToUtc(endDate)
            if (endDate.diff(lastDatasetDate, "d") >= 0) {
                const lastDate = newEndDate
                response.dataSets[1].dataSet.push([getDateTimeString(lastDate), null, null, null, null, null])
                response.dataSets[2].dataSet.push([getDateTimeString(lastDate), null, null, null, null, null])
            }
        }

        return response
    }

    addLocalOffset = (response: HomePageResponse): HomePageResponse => {
        const browserOffsetMintues = getUtcOffset(moment())
        response.dataSets[1].dataSet = response.dataSets[1].dataSet.map(datum => {
            datum[0] = addOffset(datum[0], browserOffsetMintues)
            return datum
        })
        response.dataSets[2].dataSet = response.dataSets[2].dataSet.map(datum => {
            datum[0] = addOffset(datum[0], browserOffsetMintues)
            return datum
        })

        return response
    }

    actions = {
        getHomePageData: async (apiEndpoint: HOME_PAGE_API_ENDPOINTS, startDate?: Moment, endDate?: Moment) => {
            let homePageData: HomePageResponse
            // TODO let cache happen
            if (false && this.state.cachedResponses.has(apiEndpoint)) {
                homePageData = this.state.cachedResponses.get(apiEndpoint) as HomePageResponse
            } else {
                const url = `${BASE_API_URL}${apiEndpoint}`
                const axiosResponse: AxiosResponse<HomePageResponse> = await axios.get(url)
                homePageData = axiosResponse.data
                this.state.cachedResponses.set(apiEndpoint, homePageData)
            }
            const sortedData = this.sortHomePageResponse(homePageData)

            // Get dataset min and max dates from the algae data.
            const dataSet = sortedData.dataSets[1].dataSet
            const algaeMinDate = dataSet[0][0]
            const algaeMaxDate = dataSet[dataSet.length - 1][0]
            this.state.algaeMinDate = algaeMinDate
            this.state.algaeMaxDate = algaeMaxDate

            // Add local offset for proper date dispaly in Nivo
            // We always want the date to be the date at Detroit Lake.
            this.addLocalOffset(sortedData)
            this.state.ranges = [
                {
                    label: "YTD",
                    startDate: dateStringToMomentDate(algaeMinDate),
                    endDate: dateStringToMomentDate(algaeMaxDate)
                },
                {
                    label: "Last 30 Days",
                    startDate: getNow().subtract(30, "d"),
                    endDate: dateStringToMomentDate(algaeMaxDate)
                },
                {
                    label: "Last 2 Weeks",
                    startDate: getNow().subtract(DEFAULT_RANGE, "d"),
                    endDate: MAX_DATE // dateStringToMomentDate(algaeMaxDate)
                }
            ]
            if (!startDate && !endDate) {
                // Here we assume this is in the initial so default filter to last 2 weeks
                startDate = this.state.ranges[INITIAL_DATE_RANGE_INDEX].startDate
                endDate = this.state.ranges[INITIAL_DATE_RANGE_INDEX].endDate
            }
            const filteredData = this.filterHomePageResponse(sortedData, startDate, endDate)
            this.state.homePageData = filteredData

            return filteredData
        },

        getDashboardSiteData: async (apiEndpoint: DASHBOARD_SITE_API_ENDPOINTS) => {
            if (this.state.cachedResponses.has(apiEndpoint)) {
                this.state.dashboardSiteData = this.state.cachedResponses.get(apiEndpoint) as DashboardSiteResponse
                return
            }
            const url = `${BASE_API_URL}${apiEndpoint}`
            const response: AxiosResponse<DashboardSiteResponse> = await axios.get(url)
            this.state.cachedResponses.set(apiEndpoint, response.data)
            this.state.dashboardSiteData = response.data
            return response.data
        },

        getDashboardYsiData: async (apiEndpoint: DASHBOARD_YSI_API_ENDPOINTS) => {
            if (this.state.cachedResponses.has(apiEndpoint)) {
                this.state.dashboardYsiData = this.state.cachedResponses.get(apiEndpoint) as DashboardYsiResponse
                return
            }
            const url = `${BASE_API_URL}${apiEndpoint}`
            const response: AxiosResponse<DashboardYsiResponse> = await axios.get(url)
            this.state.cachedResponses.set(apiEndpoint, response.data)
            this.state.dashboardYsiData = response.data
            return response.data
        },

        getDashboardMetData: async (apiEndpoints: (DASHBOARD_MET_API_ENDPOINTS | undefined)[]) => {
            this.state.dashboardMetDataList = [undefined, undefined]
            await apiEndpoints.forEach(async (apiEndpoint, i) => {
                if (!apiEndpoint) {
                    return
                }
                if (this.state.cachedResponses.has(apiEndpoint)) {
                    this.state.dashboardMetDataList[i] = this.state.cachedResponses.get(apiEndpoint) as DashboardMetResponse
                    return
                }
                const url = `${BASE_API_URL}${apiEndpoint}`
                const response: AxiosResponse<DashboardMetResponse> = await axios.get(url)
                this.state.cachedResponses.set(apiEndpoint, response.data)
                this.state.dashboardMetDataList[i] = response.data
            })
        },
        getProjectPageData: async (apiEndpoint: SO_WHAT_PAGE_API_ENDPOINTS) => {
            if (this.state.cachedResponses.has(apiEndpoint)) {
                this.state.projectPageData = this.state.cachedResponses.get(apiEndpoint) as SoWhatPageResponse
                return
            }
            const url = `${BASE_API_URL}${apiEndpoint}`
            const response: AxiosResponse<SoWhatPageResponse> = await axios.get(url)
            this.state.cachedResponses.set(apiEndpoint, response.data)
            this.state.projectPageData = response.data
            return response.data
        },
        setPointer: async (name: string, point: Pointer) => {
            if (name === "algaeTimeSeriesPointer") {
                this.state.algaeTimeSeriesPointer = point
            } else if (name === "toxinTimeSeriesPointer") {
                this.state.toxinTimeSeriesPointer = point
            } else {
                throw Error(`Invalid pointer name ${name}`)
            }
            this.state.pointer = point
        },
        getPointer: async (name: string) => {
            let point = undefined
            if (name === "algaeTimeSeriesPointer") {
                point = this.state.algaeTimeSeriesPointer
            } else if (name === "toxinTimeSeriesPointer") {
                point = this.state.toxinTimeSeriesPointer
            } else {
                throw Error(`Invalid pointer name ${name}`)
            }
            return point
        }
    }

    async init(store: Store) {
        // do any init stuff
        return Promise.resolve()
    }
}
